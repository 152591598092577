<script>
import Layout from "../../layouts/table";
import {mapGetters} from "vuex";

/**
 * Profile component
 */
export default {
    data() {
        return {
            perPage: 10,
            currentPage: 1,
            sortBy: 'date',
            sortDesc: true,
            formatter: new Intl.NumberFormat('hu-HU', {
                style: 'currency',
                currency: 'HUF',
                maximumSignificantDigits: 1
            })
        };
    },
    computed: {
        ...mapGetters({
            items: 'credit/list'
        }),
        rows: function () {
            return this.items && this.items.length
        },
        fields: function () {
            return [
                {
                    key: "create_date",
                    label: this.$t('balance.date'),
                    thClass: 'gridjs-th',
                    sortable: true
                },
                {
                    key: "type",
                    label: this.$t('balance.change'),
                    thClass: 'gridjs-th border-left-0',
                    sortable: true
                },
                {
                    key: "credit",
                    label: this.$t('balance.balance'),
                    thClass: 'gridjs-th',
                    tdClass: 'text-end',
                    formatter: (value) => this.formatter.format(value),
                    sortable: true
                },
            ];
        },
    },
    components: {
        Layout,
    },
    created() {
        document.title = this.setTitle(this.$t('balance.balance'));
        this.$store.dispatch('credit/all');
    }
};
</script>

<template>
    <Layout>
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">{{ $t('balance.balance') }}</h4>
            </div>
            <div class="card-body p-0 pb-3 p-md-3">
                <div class="row" v-if="false">
                    <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_length" class="dataTables_length">
                            <label class="d-inline-flex align-items-center">
                                Show&nbsp;
                                <b-form-select
                                    class="form-select form-select-sm"
                                    v-model="perPage"
                                    size="sm"
                                    :options="pageOptions"
                                ></b-form-select
                                >&nbsp;entries
                            </label>
                        </div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                        <div
                            id="tickets-table_filter"
                            class="dataTables_filter text-md-end"
                        >
                            <label class="d-inline-flex align-items-center">
                                Search:
                                <b-form-input
                                    v-model="filter"
                                    type="search"
                                    placeholder="Search..."
                                    class="form-control form-control-sm ms-2"
                                ></b-form-input>
                            </label>
                        </div>
                    </div>
                    <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0">
                    <b-table
                        :items="items"
                        :fields="fields"
                        responsive="sm"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        hover
                        head-variant="light"
                    ></b-table>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="d-flex w-100 align-items-center justify-content-center justify-content-md-end">
                            <div
                                class="dataTables_paginate paging_simple_numbers"
                            >
                                <ul class="pagination pagination-rounded mb-0">
                                    <!-- pagination -->
                                    <b-pagination
                                        v-model="currentPage"
                                        :total-rows="rows"
                                        :per-page="perPage"
                                    ></b-pagination>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
